import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  approveTimeTrackerRequest,
  clearMessages,
  getEmployeesJobsTrackingRequests,
} from "../store/Tracker/trackerSlice";
import { formatDateTime, formatTime } from "../Helper/functions";
import moment from "moment/moment";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TimeTrackerRequest({ setMenu }) {
  const dispatch = useDispatch();

  const {
    loading: approveLoading,
    error,
    successMessage,
    TimeTrackerApprovalRequests,
  } = useSelector((state) => state.timeTracker);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterdData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(getEmployeesJobsTrackingRequests())
      .unwrap()
      .then((response) => {
        setFilterData(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleApprove = (trackingId) => {
    dispatch(approveTimeTrackerRequest(trackingId));
  };

  return (
    <Paper sx={{ borderRadius: 0, width: "100%", ml: 1, p: 2 }}>
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={!!successMessage || !!error}
          autoHideDuration={3000}
          onClose={() => dispatch(clearMessages())} // Clear the message on close
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Display alert on the top-right corner
        >
          <Alert
            severity={successMessage ? "success" : "error"}
            onClose={() => dispatch(clearMessages())}
          >
            {successMessage || error}
          </Alert>
        </Snackbar>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#E4EAEE", color: "white" }}>
                <TableCell sx={{ color: "black" }} align="center">
                  Employee Name
                </TableCell>
                <TableCell sx={{ color: "black" }} align="center">
                  Job Name
                </TableCell>
                <TableCell sx={{ color: "black" }} align="center">
                  Date
                </TableCell>
                <TableCell sx={{ color: "black" }} align="center">
                  Job Rate
                </TableCell>
                <TableCell sx={{ color: "black" }} align="center">
                  In Time
                </TableCell>
                <TableCell sx={{ color: "black" }} align="center">
                  Out Time
                </TableCell>
                <TableCell sx={{ color: "black" }} align="center">
                  OT Hours
                </TableCell>
                {/* <TableCell sx={{ color: "black" }} align="center">
                  OT Time
                </TableCell> */}
                <TableCell sx={{ color: "black" }} align="center">
                  Total Time
                </TableCell>
                <TableCell sx={{ color: "black" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TimeTrackerApprovalRequests && TimeTrackerApprovalRequests.length ? (
                TimeTrackerApprovalRequests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, ind) => {
                    const { employeeName, trackingData, jobData } = item;
                    const jobName = jobData.length ? jobData[0].name : "N/A";
                    return (
                      <TableRow key={ind} hover>
                        <TableCell align="center">{employeeName}</TableCell>
                        <TableCell align="center">{jobName}</TableCell>
                        <TableCell align="center">
                          {moment(trackingData.sessionDate).format('DD-MM-Y')}
                        </TableCell>
                        <TableCell align="center">
                          ${trackingData.amount}
                        </TableCell>
                        <TableCell align="center">
                          {formatDateTime(trackingData.startTime)}
                        </TableCell>
                        <TableCell align="center">
                          {formatDateTime(trackingData.stoppedTime)}
                        </TableCell>
                        <TableCell align="center">
                          {trackingData.overAmount > 0
                            ? `${trackingData.overAmount} hours`
                            : "0"}
                        </TableCell>
                        {/* <TableCell align="center">
                          {formatDateTime(trackingData.lastStartTime)}
                        </TableCell> */}
                        <TableCell align="center">{formatTime(trackingData.elapsedTime)}</TableCell>
                        <TableCell align="center" className="gap-3">
                          <div className="d-flex gap-3">
                            <button
                              className={`btn btn-${trackingData.status !== "approved" ? 'success' : 'secondary'}`}
                              onClick={() =>
                                handleApprove(item.trackingData._id)
                              }
                              disabled={trackingData.status === "approved"}
                            >
                              {trackingData.status === "approved"
                                ? "Approved"
                                : "Approve"}
                            </button>
                            {/* <button className="btn btn-danger">Reject</button> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <Typography>No Tracking Request Found !</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={TimeTrackerApprovalRequests?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default TimeTrackerRequest;
